import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    margin: "0 auto",
    maxWidth: 1024,
    padding: "30px 16px 60px",

    "& ul": {
      paddingLeft: "1.3em",
    },
  },

  title: {
    marginBottom: 60,
    marginTop: 56,

    [theme.mediaRequests.mobile]: {
      marginBottom: 40,
    },
  },

  press: {
    maxWidth: 650,
    padding: "30px 0",

    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },

  date: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
  },

  pressTitle: {
    fontSize: 30,
  },

  pressContent: {
    display: "flex",
    marginTop: 10,

    [theme.mediaRequests.mobile]: {
      flexDirection: "column",
    },

    "& .content": {
      [theme.mediaRequests.notMobile]: {
        alignItems: "flex-end",
        display: "flex",
      },
    },

    "& img": {
      alignSelf: "flex-start",
      cursor: "pointer",
      height: "auto",

      [theme.mediaRequests.notMobile]: {
        marginRight: 15,
        width: 230,
      },

      [theme.mediaRequests.mobile]: {
        marginBottom: 16,
        width: "100%",
      },
    },
  },

  gotItContainer: {
    maxWidth: 450,
  },

  gotItButton: {
    marginTop: 30,
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "Questionnaire" },
);
