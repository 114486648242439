import React, { useState, Fragment } from "react";
import Helmet from "react-helmet";

import Typography from "@ui-kit/Typography";
import useStyles from "./styles";
import Header from "@components/Header";
import Footer from "@components/FooterNew";
import META from "./meta";
import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";

import InvestorDeck from "@documents/investor_deck_services.jpg";

const PRESS = [
  {
    date: "APR 2020",
    img: InvestorDeck,
    title: "Parent Day Out Investor Deck",
  },
];

const InvestorsRelations: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <Helmet {...META} />
        <Header fixed />

        {opened && (
          <Dialog title="Investor deck" open onClose={() => setOpened(false)}>
            <div className={classes.gotItContainer}>
              <div>
                To request a copy of the Parent Day Out Investor Deck, please
                email us at:{" "}
                <a href="mailto:investor@parentdayout.com">
                  investor@parentdayout.com
                </a>
              </div>
              <Button
                color="primary"
                fullWidth
                textTransform="none"
                variant="contained"
                className={classes.gotItButton}
                onClick={() => setOpened(false)}
                bolded
              >
                Got it
              </Button>
            </div>
          </Dialog>
        )}

        <Typography
          variant="h2"
          paragraph
          align="center"
          className={classes.title}
        >
          Investor Relations
        </Typography>

        <Typography variant="h4" paragraph>
          Contact us for investor inquiries:{" "}
          <a href="mailto:investor@parentdayout.com">
            investor@parentdayout.com
          </a>
        </Typography>

        {PRESS.map((p) => (
          <div
            className={classes.press}
            onClick={() => setOpened(true)}
            key={p.img}
          >
            <div className={classes.date}>{p.date}</div>
            <div
              className={classes.pressTitle}
              dangerouslySetInnerHTML={{
                __html: p.title,
              }}
            />
            <div className={classes.pressContent}>
              <img src={p.img} alt={p.title} />
              <div className="content">
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  bolded
                  textTransform="none"
                >
                  Download original
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </Fragment>
  );
};

export default InvestorsRelations;
