import { HelmetProps } from "react-helmet";
import { globalConfig } from "@constants";

import appInfographicImage from "@images/mobile_services.png";

const PAGE_DESCRIPTION =
  "A lead generation & childcare services reservation platform for Infant - K schools";

export default {
  lint: [
    {
      href: `${globalConfig.UI_URL}/daycare-questionnaire`,
      rel: "canonical",
    },
  ],
  meta: [
    {
      content: PAGE_DESCRIPTION,
      name: "description",
    },
    {
      content: "Parent Day Out",
      property: "og:site_name",
    },
    {
      content: "Parent Day Out",
      property: "og:title",
    },
    {
      content: PAGE_DESCRIPTION,
      property: "og:description",
    },
    {
      content: appInfographicImage,
      property: "og:image",
    },
    {
      content: "Parent Day Out",
      name: "twitter:title",
    },
    {
      content: PAGE_DESCRIPTION,
      name: "twitter:description",
    },
    {
      content: appInfographicImage,
      name: "twitter:image",
    },
  ],
  title: "Investor relations | Parent Day Out",
} as HelmetProps;
